<template>
  <div class="header_view" >
    <div class="header_title" >
      <div class="logo" >
        <img class="logo_img" src="@/assets/images/logo.png" alt="">
      </div>
    </div>
    <div v-if="props.borderShow" style="height: .26rem;background-color: #f5f5f5;"></div>
  </div>

</template>

<script setup>
import { defineProps, defineEmits,onMounted } from 'vue';

import { useRouter, useRoute } from 'vue-router'
const props = defineProps({
  titleName: String,
  return: Boolean,
  borderShow: Boolean,
  fixed: Boolean,
});
const router = useRouter()
onMounted(() => {

  
let userAgent = window.navigator.userAgent.toLowerCase();
// if (userAgent.indexOf('qzapp') !== -1) {
  console.log(props.titleName)
  document.title = props.titleName

// }
});
const back = () => {
  router.go(-1)
}

</script>

<style lang='less' scoped>
.header_title {
  height: 1.1333rem;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: .4rem;
 
  .logo {
    width: 4.1733rem;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 4.1733rem;
    }
  }
}
</style>