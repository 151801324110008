
//请求接口方法集合
import {
    get,
    post,
    file

} from "./request.js";


export default {
    
    // 新闻列表
    articleViewChannelNew(params) {
        return get('/client/article/list',params);
    }, 
     // 基金会介绍
     articleViewChannelContent() {
        return get('/client/article/view/channel/content/10');
    }, 
     // 保障业务服务
     articleViewChannelService(params) {
        return get('/client/article/view/channel/Service/100',params);
    }, 
    
    // 保障业务服务
    submitinformation(params) {
        return post('/client/submitinformation',params);
    }, 
    // 新闻详情
    newsDetails(params) {
        return get('/client/article/show/'+params);
    }, 
    // 新闻详情
    getBanner(params) {
        return get('/client/advert/' + params.name);
    }, 
	//会员活动子分类
	getNewsChildCategory(){
		return get('client/article/news/category?SiteId=1&parentId=2');
	},
    //会员活动子分类
	Upload(params){
		return file('/client/upload',params);
	}
    
    
};

